import React, { Component } from 'react'
import '../TimesSection/times-section.scss'
import SVGInline from 'react-svg-inline'
import arrowUp from '../../../../../../shared/assets/arrow-up.svg'
import arrowDown from '../../../../../../shared/assets/arrow-down.svg'
import SelectLocationBox from '../../../SelectLocationBox'
import SuperResultsTimesConnector from '../../../../../../Search/components/SearchCards/SuperResultsCard/SuperResultsTimes/SuperResultsTimesConnector.jsx'
import { isOBGYN } from '../../../../../../shared/utility-functions/isOBGYN.js'

export default class TimesSection extends Component {
  constructor (props) {
    super(props)

    this.initialSelectedLocation = ''
    this.locationList = []
    this.headerText = this.props.from === 'provider-profile' ? 'New Patient Available Appointments' : 'Available Appointments'

    if (this.props.locations && this.props.locations.length > 0 && this.props.address !== null) {
      this.locationList = this.props.locations
      for (let location of this.props.locations) {
        if (Math.abs(this.props.address.lat - location.latitude) <= 0.03 && Math.abs(this.props.address.lng - location.longitude) <= 0.03) {
          this.initialSelectedLocation = location
        }
      }
      if (this.initialSelectedLocation === '') {
        this.initialSelectedLocation = this.props.locations[0]
      }
    }

    this.state = {
      showSelectLocationBox: false,
      selectedLocation: this.initialSelectedLocation
    }

    this.setShowSelectLocationBox = this.setShowSelectLocationBox.bind(this)
    this.setSelectedLocation = this.setSelectedLocation.bind(this)
  }

  setShowSelectLocationBox (value) {
    this.setState({ showSelectLocationBox: value })
  }

  setSelectedLocation (value) {
    this.setState({ selectedLocation: value })
  }

  render() {
    return (
      <div className='available-times-container'>
        <h4 className='available-times-locations-header'>{this.headerText}</h4>
        { ((this.props.preliminaryStatus != null) || (isOBGYN(this.props.provider.specialtyIds) === false)) &&
          <div>
            {(this.locationList && this.locationList.length > 0)
              ? <div className='location-holder'>
                <div className='available-times-location-label name'>{this.state.selectedLocation.departmentName}</div>
                <div className='available-times-location-label street'>{this.state.selectedLocation.address.street} {this.state.selectedLocation.address.cityStateZip}</div>
              </div>
              : null
            }
            {(this.locationList && this.locationList.length > 1)
              ? <div className=' clickable mb-16' onClick={() => { this.setShowSelectLocationBox(!this.state.showSelectLocationBox) }}>
                <a className='clickable available-times-location-label change'>Change Location</a>
                {this.state.showSelectLocationBox
                  ? <SVGInline className='dropdown-arrow' svg={arrowUp} />
                  : <SVGInline className='dropdown-arrow' svg={arrowDown} />
                }
              </div>
              : null
            }
            <SelectLocationBox selected={this.state.selectedLocation} setShowSelectLocBox={() => this.setShowSelectLocationBox(!this.state.showSelectLocationBox)} setLocation={(newLoc) => { this.setSelectedLocation(newLoc) }} show={this.state.showSelectLocationBox} locationList={this.locationList} />
          </div>
        }
  
        <SuperResultsTimesConnector
          provider={this.props.provider}
          from={this.props.from}
          setShowQuestions={this.props.setShowQuestions}
          timesDrawerClick={this.props.timesDrawerClick}
          selectedLocation={this.state.selectedLocation}
          preliminaryStatus={this.props.preliminaryStatus}
          setPreliminaryStatus={this.props.setPreliminaryStatus}
          handleCloseAll={this.props.handleCloseAll}
        />
  
        <div className='flex justify-center'>
          <div className='drawer-divider' />
        </div> 
      </div>
    )
  }
}