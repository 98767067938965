import React from 'react'
import SVGInline from 'react-svg-inline'
import ArrowRight from '../../../shared/assets/arrow-right.svg'
import './collapse-expand.scss'

const CollapseExpand = class extends React.Component {
  constructor (props) {
    super(props)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.collapseClass = this.collapseClass.bind(this)
    this.styleBuilder = this.styleBuilder.bind(this)
    this.redraw = this.redraw.bind(this)
    this.isEnabled = this.isEnabled.bind(this)
    this.state = {
      open: false
    }
  }

  toggleDropdown () {
    this.redraw()
    if (!this.isEnabled()) {
      this.setState({ open: true })
    } else {
      this.setState(prevState => {
        return { open: !prevState.open }
      })
      if (!this.state.open && this.props.customClass && window.innerWidth < 1200) {
        let element = $(`.${this.props.customClass}`)
        if (element) {
          $('html, body').animate({ scrollTop: element.offset().top }, 'slow')
        }
      }
    }
  }

  collapseClass (containerClass) {
    return this.state.open || !this.isEnabled() ? containerClass : `${containerClass} closed`
  }

  styleBuilder () {
    let closedHeight = this.props.closedHeight || 270
    if (!this.state.sectionHeight || !this.state.open) {
      return { height: closedHeight + 'px' }
    } else if (!this.isEnabled()) {
      return {}
    } else {
      return { height: this.state.sectionHeight + 'px' }
    }
  }

  redraw () {
    let section = document.querySelector('.collapse-container')
    let sectionHeight = section ? section.scrollHeight : 0
    this.setState({ sectionHeight: sectionHeight })
  }

  isEnabled () {
    let width = window.innerWidth
    if (!this.props.display) return false
    return (width > 1200 && this.props.total > this.props.display.desktop) ||
    (width > 767 && width <= 1200 && this.props.total > this.props.display.tablet) ||
    (width <= 767 && this.props.total > this.props.display.mobile)
  }

  componentDidMount () {
    let section = document.querySelector('.collapse-container')
    let sectionHeight = section ? section.scrollHeight : 0
    this.setState({ sectionHeight: sectionHeight })
  }

  render () {
    if (this.props.text) {
      this.open = this.props.text.open || 'VIEW ALL'
      this.closed = this.props.text.closed || 'VIEW LESS'
    }
    return (
      <div className={this.props.customClass ? this.props.customClass : ''}>
        <div className={this.collapseClass('collapse-container')} style={this.styleBuilder()}>
          {this.props.children}
        </div>
        {this.isEnabled() &&
          <div className='handle'>
              {(this.props.from !== 'provider-drawer') && <hr className='collapse-hr' />}
          { this.props.displayFooter && <div className='collapse-section-text text-center' onClick={this.toggleDropdown}>
            {this.state.open ? this.open : this.closed }
            <span className={this.collapseClass('expand-icon')}><SVGInline svg={ArrowRight} /></span>
          </div>}
        </div>
        }
      </div>
    )
  }
}

CollapseExpand.defaultProps = {
  from: null
};

export default CollapseExpand
