import { connect } from 'react-redux'
import OBGYNQuestionaire from './OBGYNQuestionaire.jsx'

const mapStateToProps = (props)=>{
  return ({
    componentType: props.componentType
  })
}

const mapDispatchToProps = (dispatch) => ({
  setReasonsForVisit: (reasons) => {
    window.sessionStorage.setItem('reasonsForVisitOBGYN', reasons)
    dispatch({ type: 'SET_REASONS_FOR_VISIT', value: reasons })
  },
  setSpecialAssistance: (value) => {
    window.sessionStorage.setItem('specialAssistanceOBGYN', value)
    dispatch({ type: 'SET_SPECIAL_ASSISTANCE', value: value })
  },
  setVisitTypeID: (id) => {
    window.sessionStorage.setItem('visitTypeOBGYN', id)
    dispatch({ type: 'SET_VISIT_TYPE', value: id })
  },
  fetchAppointments: () => dispatch({ type: 'FETCH_APPOINTMENTS_OPEN' }),
  hideSchedulingModal: () => {
    dispatch({ type: 'HIDE_SCHEDULING_MODAL' })
  }
})

export default connect(null, mapDispatchToProps)(OBGYNQuestionaire)
