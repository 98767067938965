import { put, take, select } from 'redux-saga/effects'
import { sortOrder } from '../selectors/doctor-search-filter-selectors'
import { updateCookie } from '../../shared/utility-functions/'

export default function * providerDistanceSaga () {
  while (true) {
    const searchLocation = yield take('SET_SELECTED_SEARCH_LOCATION')
    console.log('searchLocation', searchLocation)
    if (searchLocation.value.address && searchLocation.value.latitude && searchLocation.value.longitude) {
      updateCookie({
        locq: searchLocation.value.address,
        lat: searchLocation.value.latitude,
        lng: searchLocation.value.longitude
      }, 'fad_search')
      let sort = yield select(sortOrder)
      if (sort !== 'Random') yield put({ type: 'SET_DOCTOR_SEARCH_SORT_ORDER', value: 'Distance' })
    }
  }
}
