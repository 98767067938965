import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./questions-modal.scss";
import infoIcon from "../../../shared/assets/info.svg";
import calendarIcon from "../../../shared/assets/calendar-blue.svg";
import SVGInline from "react-svg-inline";
import { scheduleQuestionEvent } from "../../../shared/utility-functions/googleTagManager";
import DayPicker from "react-day-picker";
import "../../../shared/sass/calendar-date-picker.scss";

const QuestionsModalNew = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(props.service.getCurrentQuestion());
  const [totalQuestions, setTotalQuestions] = useState(props.service.numberOfQuestions);
  const [inputDate, setInputDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dayPickerRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      if (dayPickerRef.current && dayPickerRef.current.base && !dayPickerRef.current.base.contains(e.target)) {
        setShowDatePicker(false);
      }
    };
    window.document.addEventListener("mousedown", handleClick);

    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    const currentQues = props.service.getCurrentQuestion();
    setCurrentQuestion((prevState) => {
      if (prevState.question != currentQues.question) {
        document.activeElement.blur();
        return currentQues;
      }
      return prevState;
    });
    setTotalQuestions(props.service.numberOfQuestions);
  }, [props.service]);

  const actionHandler = (answer) => {
    const dataLayerObject = {
      flow: props.flow,
      scheduleQuestionNumber: undefined,
      scheduleQuestion: currentQuestion.question,
      scheduleQuestionAnswer: answer.text,
    };

    scheduleQuestionEvent(dataLayerObject);

    if (answer.error) {
      props.submissionHandler(answer);
    } else {
      props.service.answerQuestion({ ...answer, inputDate: inputDate });
      setCurrentQuestion(props.service.getCurrentQuestion());
      if (currentQuestion === "done") {
        props.submissionHandler();
      } else {
        let answer = document.querySelector("button.answer");
        if (answer) {
          answer.focus();
        }
      }
    }
  };

  let positionValue = currentQuestion.number % 2 === 0 ? "relative" : "initial"; // doing this for a safari bug to force a redraw of question text
  return (
    <div className={`questions-modal ${props.flow}`}>
      {currentQuestion.type && currentQuestion.type === "notice" && (
        <SVGInline
          svg={infoIcon}
          className='error-icon'
        />
      )}
      {currentQuestion.image ? (
        <SVGInline
          svg={currentQuestion.image}
          className='error-icon'
        />
      ) : null}
      <div className='title'>{currentQuestion.title ? currentQuestion.title : props.title}</div>
      {currentQuestion.type !== "notice" && totalQuestions > 1 && (
        <p className='step-numbers'>
          {currentQuestion.number} of {totalQuestions}
        </p>
      )}
      <p
        className='question'
        style={{ position: positionValue }}>
        {currentQuestion.question}
      </p>
      {currentQuestion.input ? (
        <div className='date-input-container'>
          <div
            className='date-textbox'
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}>
            <input value={inputDate == null ? "mm/dd/yyyy" : moment(inputDate).format("MM/DD/YYYY")} />
            <SVGInline
              svg={calendarIcon}
              className='error-icon'
            />
          </div>
          {showDatePicker ? (
            <DayPicker
              ref={dayPickerRef}
              onDayClick={(evt) => {
                setInputDate(new Date(evt));
                setShowDatePicker(false);
              }}
              selectedDays={inputDate}
            />
          ) : null}
        </div>
      ) : null}
      {currentQuestion &&
        currentQuestion.answers &&
        currentQuestion.answers.map((answer, index) => {
          return (
            <button
              key={index}
              className={`btn btn-primary answer question-number-${currentQuestion.number} ${
                answer.className ? answer.className : ""
              } button-${index}`}
              onClick={() => actionHandler(answer)}
              disabled={answer.required ? (inputDate == null ? true : false) : false}>
              {answer.text}
            </button>
          );
        })}
    </div>
  );
};
export default QuestionsModalNew;
