import moment from 'moment'
import React, {useState, useEffect, useRef} from 'react'
import './provider-drawer.scss'
import Modal from '../../../../shared/components/Modal'
import { getAgeInYears, getPhoneNumber } from '../../../../shared/utility-functions'
import '../SkeletonDrawer/skeleton-drawer.scss'
import ProfileInfo from '../ProviderDrawer/ProviderDrawerSections/ProfileInfo/index'
import Locations from '../ProviderDrawer/ProviderDrawerSections/Locations/index'
import AreasOfFocus from '../ProviderDrawer/ProviderDrawerSections/AreasOfFocus/index'
import About from '../ProviderDrawer/ProviderDrawerSections/About/index'
import Credentials from '../ProviderDrawer/ProviderDrawerSections/Credentials/index'
import TimesSection from '../ProviderDrawer/ProviderDrawerSections/TimesSection/index'
import RatingCategories from '../ReviewsDrawer/RatingCategories/index'
import StarBar from '../../../../shared/components/StarBar'
import PressGaneyModal from '../../DynamicDrawers/PressGaneyModal/index'
import InfoIcon from '../../../../shared/assets/info-reviews-drawer.svg'
import CloseIcon from '../../../../shared/assets/x.svg'
import SVGInline from 'react-svg-inline'

const ProviderDrawer = ({ provider, profilePageURL, fadeOutType, from, setShowQuestions, onAnimationEnd, numberOfReviews, timesDrawerShow, reviewsDrawerShow, distance, options, show, cardOptions, handleClose, handleCloseAll, timesDrawerClick, reviewsDrawerClick, preliminaryStatus, setPreliminaryStatus }) => {
  const years = provider.experience ? getAgeInYears(moment(provider.experience, 'DD/MM/YYYY')) : null
  const address = provider.address ? provider.address : null
  const addresses = provider.addresses && provider.addresses.length > 0 ? provider.addresses : null
  const locations = provider.locations && provider.locations.length > 0 ? provider.locations : null
  const certifications = provider.certifications || []
  const education = provider.education || []
  const drawerRef = useRef()

  const [pressGaneyShow, setPressGaneyShow] = useState(false)

  useEffect(() => {
    const handleClickOutsideDrawer = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleClose()
      }
    }

    if ((reviewsDrawerShow === false) && (timesDrawerShow === false)) {
      document.addEventListener('mousedown', handleClickOutsideDrawer)
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDrawer)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDrawer)
    }
  }, [drawerRef, reviewsDrawerShow, timesDrawerShow])

  return (
    <span className='skeleton-drawer-outer' onAnimationEnd={() => { onAnimationEnd() }}>
      <Modal showModal={show} hideFunction={handleClose} hideCloseButton customClass={(fadeOutType === 'provider' || fadeOutType === 'all') ? 'animate-in animate-out-all' : 'animate-in'}>
        <div className='provider-drawer-container' ref={drawerRef}>
          <div className='blue-rectangle' >
            <div className='close-all' onClick={handleClose} ><SVGInline className='close-all-x' svg={CloseIcon} /></div>
          </div>

          <ProfileInfo provider={provider} profilePageURL={profilePageURL} cardOptions={cardOptions} years={years} reviewsDrawerClick={reviewsDrawerClick} />

          <TimesSection provider={provider} from={from} setShowQuestions={setShowQuestions} locations={locations} address={address} timesDrawerClick={timesDrawerClick} preliminaryStatus={preliminaryStatus} setPreliminaryStatus={setPreliminaryStatus} handleCloseAll={handleCloseAll} />
          
          <Locations address={address} addresses={addresses} />

          <AreasOfFocus provider={provider} />

          <About provider={provider} years={years} />

          <Credentials certifications={certifications} education={education} />

          {/* Ratings and Reviews */}
          {(provider.rating && provider.rating.Rating)
            ? <div id={`reviews-container-${provider.npi}`} className='ratings-reviews-container'>

              <div className='ratings-header-container'>
                <div className='ratings-header'>Ratings and Reviews</div>
                <SVGInline className='info-icon' svg={InfoIcon} onClick={() => { setPressGaneyShow(true) }} />
              </div>
              {pressGaneyShow
                ? <PressGaneyModal show={pressGaneyShow} handleClose={() => { setPressGaneyShow(false) }} />
                : null
              }

              {(provider.rating && provider.rating.Rating)
                ? <span className='overall-rating-section' >
                  <h6>Overall Rating</h6>
                  <div className='overall-ratings-text'>{provider.rating.Rating} of 5</div>
                  <div className='ratings-starbar'>
                    <StarBar rating={provider.rating.Rating} />
                  </div>
                </span>
                : null
              }

              <div className='mb-12'>
                {
                  provider.reviews && provider.reviews.ratingCategories && provider.reviews.ratingCategories.length > 0 &&
                  <RatingCategories ratingCategories={provider.reviews.ratingCategories} />
                }
              </div>
              {(provider.rating && provider.rating.Rating && numberOfReviews && numberOfReviews > 0)
                ? <a className='reviews-drawer-click clickable mb-24' onClick={reviewsDrawerClick}>Show {numberOfReviews} Reviews</a>
                : null
              }
            </div>
            : null
          }
          {/* ----------------------- */}

        </div>
      </Modal>
    </span>
  )
}

export default ProviderDrawer
